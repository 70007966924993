import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import GooglePlay from "../assets/GooglePlaySm3.png";
import AppStore from "../assets/AppStoreSm3.png";
import Dashboard from "../assets/DashboardSm.png";
import { useSpring, animated } from "@react-spring/web";

const logoStyle = {
  width: "40px",
  height: "auto",
  margin: "8px",
  cursor: "pointer",
  borderRadius: "2px",
};

const CTALogos = [
  {
    icon: GooglePlay,
    name: "Google Play",
    link: "https://play.google.com/store/apps/details?id=com.clinic.arogriha",
  },
  {
    icon: AppStore,
    name: "App Store",
    link: "https://apps.apple.com/us/app/arogriha/id6737303714",
  },
//   {
//     icon: Dashboard, 6737303714
//     name: "Dashboard",
//     link: "",
//   },
];

export default function StickyFooter() {
  const [hoveredIndex, setHoveredIndex] = React.useState(null);

  // Single spring to control hover animation for text
  const springProps = useSpring({
    transform: hoveredIndex !== null ? "translateX(0px)" : "translateX(100px)",
    opacity: hoveredIndex !== null ? 1 : 0,
    config: { duration: 200 },
  });

  return (
    <Box
      component="div"
      sx={{
        position: "sticky",
        bottom: 0,
        right: 0,
        display: "flex",
        justifyContent: "flex-end", // Align content to the right
        alignItems: "center",
        bgcolor: "transparent",
        p: 2,
        zIndex: 10,
        width: "100%", // Use 100% to span across the container
      }}
    >
      <Grid container direction="column" alignItems="flex-end">
        {CTALogos.map((logo, index) => (
          <Box
            key={index}
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
            onClick={() => {
              if (logo.link) window.open(logo.link, "_blank");
            }}
          >
            {/* <animated.div
              style={hoveredIndex === index ? springProps : { opacity: 0 }}
            >
              <Typography
                variant="caption"
                sx={{
                  color: "white",
                  mb: 1,
                }}
              >
                {logo.name}
              </Typography>
            </animated.div> */}
            <img src={logo.icon} alt={logo.name} style={logoStyle} />
          </Box>
        ))}
      </Grid>
    </Box>
  );
}
